import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, Marker, DirectionsRenderer } from "@react-google-maps/api";
import mapStyles from "../../../assets/json/customMapViewDark.json";
import { EDeliveryPickupStatus, EDeliveryStatus, ILocationAddress } from "../../../types/interfaces";
import { theme } from "../../../theme";

interface MapPreviewProps {
  start: ILocationAddress;
  waypoints: ILocationAddress[];
  orderDetails?: any;
}

const MapPreview: React.FC<MapPreviewProps> = ({
  start,
  waypoints,
  orderDetails,
}) => {
  // States
  const [courierDirection, setCourierDirection] = 
    useState<google.maps.DirectionsResult | null>(null);
  const [prevMarkers, setPrevMarkers] = useState<string>("");

  // Refs
  const mapRef = useRef<google.maps.Map>();

  // Calculate and set directions from courier to in-progress dropoff
  useEffect(() => {
    if (!orderDetails?.courier?.location) {
      setCourierDirection(null);
      return;
    }

    const inProgressDropoff = waypoints.find(
      waypoint => waypoint.status === EDeliveryStatus.IN_PROGRESS
    );

    let destination;
    if (orderDetails.pickup.status === EDeliveryPickupStatus.ON_THE_WAY_TO_PICKUP) {
      destination = {
        lat: start.lat,
        lng: start.lng
      };
    } else if (inProgressDropoff) {
      destination = {
        lat: inProgressDropoff.lat,
        lng: inProgressDropoff.lng
      };
    } else {
      setCourierDirection(null);
      return;
    }

    const directionsService = new window.google.maps.DirectionsService();
    const directionsRequest: google.maps.DirectionsRequest = {
      origin: {
        lat: orderDetails.courier.location.latitude,
        lng: orderDetails.courier.location.longitude,
      },
      destination,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    directionsService.route(directionsRequest, (result, status) => {
      if (status === "OK" && result) {
        setCourierDirection(result);
      } else {
        setCourierDirection(null);
      }
    });
  }, [waypoints, orderDetails?.courier?.location, orderDetails?.pickup?.status, start]);

  // Fit map bounds to show all markers with animation
  const fitBoundsToMarkers = (animate: boolean = false) => {
    if (!mapRef.current) return;

    const bounds = new window.google.maps.LatLngBounds();

    // Add start location if valid
    if (start?.lat && start?.lng) {
      bounds.extend(new window.google.maps.LatLng(start.lat, start.lng));
    }

    // Add valid waypoints
    waypoints.forEach((waypoint) => {
      if (waypoint?.lat && waypoint?.lng) {
        bounds.extend(new window.google.maps.LatLng(waypoint.lat, waypoint.lng));
      }
    });

    // Add courier location if exists and valid
    if (orderDetails?.courier?.location?.latitude && orderDetails?.courier?.location?.longitude) {
      bounds.extend(
        new window.google.maps.LatLng(
          orderDetails.courier.location.latitude,
          orderDetails.courier.location.longitude
        )
      );
    }

    // Only fit bounds if we have valid points
    if (!bounds.isEmpty()) {
      if (animate) {
        mapRef.current.panToBounds(bounds, { top: 100, right: 100, bottom: 100, left: 100 });
        setTimeout(() => {
          if (mapRef.current) {
            mapRef.current.setZoom((mapRef.current.getZoom() || 5) - 0.5);
          }
        }, 500);
      } else {
        mapRef.current.fitBounds(bounds);
        mapRef.current.setZoom((mapRef.current.getZoom() || 5) - 0.5);
      }
    }
  };

  // Check for marker changes and animate accordingly
  useEffect(() => {
    const currentMarkers = JSON.stringify({
      start,
      waypoints,
      courier: orderDetails?.courier?.location
    });

    if (prevMarkers && prevMarkers !== currentMarkers) {
      fitBoundsToMarkers(true);
    }
    setPrevMarkers(currentMarkers);
  }, [waypoints, orderDetails?.courier?.location, start]);

  return (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
      onLoad={(map) => {
        mapRef.current = map;
        fitBoundsToMarkers();
      }}
      options={{
        styles: mapStyles,
        minZoom: 3,
        maxZoom: 18,
      }}
    >
      {/* Pickup location marker */}
      {start?.lat && start?.lng && (
        <Marker
          position={{ lat: start.lat, lng: start.lng }}
          icon={{
            url: require("../../../assets/markers/pickup_pin.png"),
            scaledSize: new window.google.maps.Size(30, 30),
          }}
          title="Pickup location"
        />
      )}

      {/* Courier to in-progress dropoff directions */}
      {courierDirection && (
        <DirectionsRenderer
          directions={courierDirection}
          options={{
            suppressMarkers: true,
            polylineOptions: {
              strokeColor: "#ffe6a7",
              strokeWeight: 1
            },
          }}
        />
      )}

      {/* Courier location marker */}
      {orderDetails?.courier?.location?.latitude && orderDetails?.courier?.location?.longitude && (
        <Marker
          position={{
            lat: orderDetails.courier.location.latitude,
            lng: orderDetails.courier.location.longitude,
          }}
          icon={{
            url: require("../../../assets/markers/motorcycle.png"),
            scaledSize: new window.google.maps.Size(45, 45),
          }}
          title="Courier location"
        />
      )}

      {/* Waypoint markers */}
      {waypoints.map((data: any, index) => (
        data?.lat && data?.lng ? (
          <Marker
            key={index}
            position={{ lat: data.lat, lng: data.lng }}
            icon={{
              url: getWaypointIcon(data.status),
              scaledSize: new window.google.maps.Size(30, 30),
            }}
            title={data.address}
          />
        ) : null
      ))}
    </GoogleMap>
  );
};

// Helper function to get waypoint icon based on status
const getWaypointIcon = (status?: EDeliveryStatus) => {
  switch (status) {
    case EDeliveryStatus.PENDING:
      return require("../../../assets/markers/clicked_pin.png");
    case EDeliveryStatus.CONFIRMED:
    case EDeliveryStatus.IN_PROGRESS:
      return require("../../../assets/markers/mid_pin.png");
    case EDeliveryStatus.CANCELLED:
      return require("../../../assets/markers/end_pin.png");
    case EDeliveryStatus.DELIVERED:
      return require("../../../assets/markers/start_pin.png");
    default:
      return require("../../../assets/markers/clicked_pin.png");
  }
};

export default MapPreview;
