import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  GlobalButton,
  GlobalButtonPlain,
  Link,
  Row,
  TextInputRawWrapper,
  TextInputWrapper,
  theme,
  transition,
} from "../theme";
import { Catch } from "../components/Landing/Introduction.section";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { LuSmartphone } from "react-icons/lu";
import { CiMail } from "react-icons/ci";
import { CgPassword } from "react-icons/cg";
import "react-phone-number-input/style.css";
import countryCodes from "../utils/country_codes.json";
import { PinInput } from "react-input-pin-code";
import Loader from "../components/Shared/Loader.component";
import { formHandler } from "../services/form_handler.service";
import FormMessage from "../components/Shared/FormMessage.component";
import useExternalAPI from "../hooks/useExternalAPI";
import { Info } from "../styles/modal.styles";

const Signin = () => {
  const [type, setType] = useState<"email" | "phone">("email");
  const [pin, setPin] = useState(["", "", "", "", ""]);
  const [loading, setLoading] = useState<boolean>(false);
  const [require_activation, setRequireActivation] = useState<{
    status: boolean;
    value: string | null;
  }>({ status: false, value: null });
  const [formMessage, setFormMessage] = useState<{
    message: string | null;
    status: "success" | "info" | "error";
  }>({ message: null, status: "info" });
  const [country_code, setCountryCode] = useState<string>("233");
  const { fetchData } = useExternalAPI();

  const request = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setFormMessage((e) => ({ ...e, message: null }));

    try {
      let formData = formHandler(e);

      if (type === "email") {
        formData = {
          email: formData["email"],
          password: formData["password"],
        };
      } else if (type === "phone") {
        if (pin.includes("")) {
          setFormMessage({
            status: "error",
            message: "please fill all fields of the PIN.",
          });
          return;
        }
        formData = {
          phone: `+${country_code}${
            formData["phone"].startsWith("0")
              ? formData["phone"].substring(1)
              : formData["phone"]
          }`,
          pin: pin.join(""),
        };
      }
      // console.log({ formData });
      const submit = await fetchData({
        url: "account/login",
        method: "POST",
        body: formData,
      });

      if (!submit["status"] && submit["require_acitivation"]) {
        setFormMessage({ status: "error", message: submit["message"] });
        setRequireActivation({
          status: true,
          value: type === "email" ? formData["email"] : formData["phone"],
        });
      } else setFormMessage({ status: "success", message: submit["message"] });
    } catch (e: any) {
      console.error({ e });
      setFormMessage({
        status: "error",
        message: e?.message || "An error has occured, try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Left>
        <Link to="/">
          <img
            src={require("../assets/logo_light.png")}
            width={50}
            height={50}
            style={{ position: "absolute", left: 20, top: 20 }}
            alt="Logo"
          />
        </Link>
        <img
          src={require("../assets/map_login.png")}
          style={{ width: "105%", marginTop: "20%" }}
          alt="map"
        />
        <Power>
          Powered by{" "}
          <a
            href="https://cwintechsolutions.com/"
            target="_blank"
            style={{ color: theme.yellow, textDecoration: "none" }}
          >
            Cwin Tech Solutions
          </a>{" "}
          @ 2024.
        </Power>
      </Left>
      <Right onSubmit={(e) => request(e)}>
        <Link to="/">
          <Logo src={require("../assets/logo_min_dark.png")} />
        </Link>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Catch style={{ margin: 0 }}>Sign in</Catch>
          <SignupWrapper style={{ textAlign: "left" }}>
            Access account using the options
          </SignupWrapper>
          <Row style={{ gap: 10, marginTop: 20 }}>
            <LoginType
              active={type === "email"}
              onClick={() => setType("email")}
              type="button"
            >
              <MdOutlineAlternateEmail
                size={20}
                color={type === "email" ? theme.orange : theme.font}
              />{" "}
              Email
            </LoginType>
            <LoginType
              active={type !== "email"}
              onClick={() => setType("phone")}
              type="button"
            >
              <LuSmartphone
                size={15}
                color={type === "phone" ? theme.orange : theme.font}
              />{" "}
              Phone
            </LoginType>
          </Row>
          <InputWrapper type={"email"}>
            <div
              style={{
                width: type === "email" ? "100%" : 0,
                opacity: type === "email" ? 1 : 0,
                transition: transition,
                overflow: "hidden",
              }}
            >
              <TextInputWrapper
                style={{ padding: "10px 10px", border: "none" }}
              >
                <CiMail />
                <TextInputRawWrapper
                  placeholder="Enter email"
                  type="email"
                  name="email"
                  style={{ fontSize: 18 }}
                  required={type === "email"}
                />
              </TextInputWrapper>
              <TextInputWrapper
                style={{ padding: "10px 10px", border: "none", marginTop: 20 }}
              >
                <CgPassword />
                <TextInputRawWrapper
                  placeholder="Enter password"
                  type="password"
                  name="password"
                  style={{ fontSize: 18 }}
                  required={type === "email"}
                />
              </TextInputWrapper>
            </div>
            <div
              style={{
                width: type === "phone" ? "100%" : 0,
                opacity: type === "phone" ? 1 : 0,
                transition: transition,
                overflow: "hidden",
              }}
            >
              <TextInputWrapper
                style={{
                  marginBottom: 20,
                  padding: "10px 10px",
                  border: "none",
                }}
              >
                <select
                  style={{
                    fontSize: 18,
                    background: "transparent",
                    border: "none",
                    width: 100,
                    outline: "none",
                    font: theme.font,
                  }}
                  defaultValue={"233"}
                  value={country_code}
                  onChange={(e) => setCountryCode(e.target.value)}
                >
                  {countryCodes.map((data, index) => (
                    <option
                      key={index}
                      value={data.code}
                    >{`${data.flag} +${data.code}`}</option>
                  ))}
                </select>
                <TextInputRawWrapper
                  placeholder="Enter phone no."
                  type="number"
                  style={{ fontSize: 18 }}
                  name="phone"
                  required={type === "phone"}
                />
              </TextInputWrapper>
              <PinInput
                containerStyle={{
                  marginTop: 20,
                  marginBottom: 15,
                  marginLeft: 5,
                }}
                values={pin}
                size="lg"
                type="text"
                mask={true}
                validBorderColor={theme.yellow}
                focusBorderColor={theme.orange}
                onChange={(value, index, values) => setPin(values)}
              />
              <Info>This will be the 5 pin code used inplace of password.</Info>
            </div>
          </InputWrapper>
          <InputWrapper style={{ height: "auto" }} type={"email"}>
            <FormMessage
              message={formMessage.message}
              status={formMessage.status}
            />
          </InputWrapper>
          <Signup to="/resetpassword" style={{ fontWeight: 500, fontSize: 14, margin: 0, float: 'right', width: 'min-content', textWrap: 'nowrap' }}>
              Forgot password?
            </Signup>
          {require_activation.status && (
            <Info style={{ fontStyle: "normal", margin: 0 }}>
              To verify the acount click:{" "}
              <Link
                to={`/signup?type=${type}&value=${require_activation.value}`}
              >
                <GlobalButtonPlain
                  style={{ fontWeight: 600, color: theme.orange }}
                  type="button"
                  // onClick={()=> resentOpt()}
                >
                  here
                </GlobalButtonPlain>
              </Link>
            </Info>
          )}
          {!loading && (
            <LocalButton
              style={{
                backgroundColor: theme.font,
                color: theme.background,
                marginTop: 30,
              }}
              type="submit"
              // onClick={() => setLoading((e) => !e)}
            >
              Submit
            </LocalButton>
          )}
          {loading && (
            <Loader
              message="signing in"
              color={theme.orange}
              style={{
                width: 300,
                marginTop: 10,
                justifyContent: "flex-start",
              }}
            />
          )}

          <SignupWrapper style={{ textAlign: "left", marginTop: 20 }}>
            Don't have an account?{" "}
            <Signup to="/signup" style={{ fontWeight: 600 }}>
              Sign up
            </Signup>
          </SignupWrapper>
        </div>
      </Right>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: 100vh;
  padding: 1%;
`;

const Left = styled.div`
  width: 40%;
  border-radius: 30px;
  background-color: ${theme.font};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Right = styled.form`
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 2%;
  justify-content: space-between;
  padding-left: 10%;

  @media (max-width: 768px) {
    padding-left: 4%;
  }
`;

const Power = styled.p`
  color: ${theme.background};
  margin: 0;
  font-size: 10px;
  font-weight: 600;
  position: absolute;
  bottom: 20px;
  left: 20px;
`;

const SignupWrapper = styled.p`
  color: #93989f;
  margin: 0;
  font-size: 16px;
  /* font-weight: 600; */
  text-align: right;
`;

const Logo = styled.img`
  display: none;
  position: absolute;
  left: 2%;
  top: 1%;
  width: 50px;
  height: 50px;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Signup = styled(Link)`
  transition: ${transition};
  color: ${theme.font};

  &:hover {
    color: ${theme.orange};
  }
`;

interface LoginTypeProps {
  active: boolean;
}

const LoginType = styled(GlobalButton)<LoginTypeProps>`
  border: 1px solid ${(props) => (props.active ? theme.orange : "#c6c6c6")};
  border-radius: 10px;
  padding: 10px 24px;
  height: 40px;
  background-color: ${(props) => (props.active ? theme.accent : "transparent")};
  gap: 7px;

  &:hover {
    border-color: ${theme.yellow};
  }
`;

const InputWrapper = styled.div<{ type: "email" | "phone" | "authentiate" }>`
  display: flex;
  flex-wrap: nowrap;
  width: 500px;
  transition: ${transition};
  align-items: center;
  height: ${(props) =>
    props.type === "email"
      ? "180px"
      : props.type === "phone"
      ? "250px"
      : "150px"};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const LocalButton = styled(GlobalButton)`
  width: 500px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default Signin;
