import React, { useEffect, useState } from "react";
import { IoArrowBack, IoCall, IoCloseOutline } from "react-icons/io5";
import { GoPaste } from "react-icons/go";
import {
  GlobalButton,
  GlobalTextInput,
  Row,
  TextInputRawWrapper,
  TextInputWrapper,
  theme,
  transition,
} from "../../../theme";
import countryCodes from "../../../utils/country_codes.json";
import Loader from "../Loader.component";
import { formHandler } from "../../../services/form_handler.service";
import useExternalAPI from "../../../hooks/useExternalAPI";
import FormMessage from "../FormMessage.component";
import MapPreview from "../Maps/MapPreview.component";
import OrderDetails from "../../Landing/OrderDetails.component";
import { useSocket } from "../../../hooks/useSocket";
import {
  getColorByStatus,
} from "../../../services/data_handler.service";
import {
  Close,
  Info,
  LinkWrapper,
  PasteButton,
  TopSection,
  WidthController,
} from "../../../styles/modal.styles";
import { EDeliveryStatus, isDeliveryActive } from "../../../types/interfaces";
import { canCallCourier } from "../../../utils/tools/delivery_tools";

interface QueryOrderProps {
  preload: {
    code: string;
    pickupPhone: string;
  };
  orderData: any;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOrderInfo: React.Dispatch<any>;
  setOrderData: React.Dispatch<any>;
}

const QueryOrder: React.FC<QueryOrderProps> = ({
  preload,
  visible,
  setVisible,
  setCancelModal,
  setOrderInfo,
  orderData,
  setOrderData,
}) => {
  const [values, setValues] = useState(preload.code);
  const [stage, setStage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [formMessage, setFormMessage] = useState({
    message: null as string | null,
    status: "info" as "success" | "info" | "error",
  });
  const [roomName, setRoomName] = useState<string | null>(null);
  const [contactCourier, setContactCourier] = useState<{
    isActive: boolean;
    canCall: boolean;
  }>({ isActive: false, canCall: false });

  const { fetchData } = useExternalAPI();
  const {
    createOrJoinRoom,
    leaveRoom,
    driverLocation,
    refreshRequest,
    isConnected,
  } = useSocket();

  useEffect(() => {
    setContactCourier({
      isActive: isDeliveryActive(orderData?.status),
      canCall: canCallCourier(orderData?.status),
    });
  }, [orderData?.status]);

  useEffect(() => {
    if (visible && orderData && isConnected) {
      const room = `${orderData.code}-${orderData.pickup.phone}`;
      setRoomName(room);
      createOrJoinRoom(room);
    }
  }, [visible, orderData, isConnected]);

  useEffect(() => {
    if (!visible || !orderData) {
      roomName && leaveRoom(roomName);
      setRoomName(null);
      !visible && setStage(1);
    }
  }, [visible, orderData, roomName]);

  useEffect(() => {
    if (driverLocation) {
      setOrderData((data: any) =>
        data
          ? {
              ...data,
              courier: {
                ...data.courier,
                location: driverLocation,
              },
            }
          : data
      );
    }
  }, [driverLocation, setOrderData]);

  useEffect(() => {
    refreshRequest && refreshOrderData();
  }, [refreshRequest]);

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => setLoading(false), 10000);
      return () => clearTimeout(timer);
    }
  }, [loading]);

  useEffect(() => {
    setValues(preload.code);
  }, [preload.code]);

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setValues(text);
    } catch (error) {}
  };

  const refreshOrderData = async () => {
    if (!orderData) return;

    try {
      const response = await fetchData({
        url: `delivery/order?code=${orderData.code}&pickupPhone=${orderData.pickup.phone}`,
        method: "GET",
      });

      response.status && setOrderData(response.data);
    } catch (error) {}
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = formHandler(e);
      const response = await fetchData({
        url: `delivery/order?code=${formData.code}&pickupPhone=${formData.pickupPhone}`,
        method: "GET",
      });

      if (response.status) {
        setStage(2);
        setOrderData(response.data);
        setFormMessage({
          status: "success",
          message: response.message || "Successful",
        });
        setTimeout(() => {
          setFormMessage({ message: null, status: "info" });
        }, 2000);
      } else {
        setFormMessage({ status: "error", message: response.message });
      }
    } catch (e: any) {
      setFormMessage({
        status: "error",
        message: e.message || "An error has occurred",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCallCourier = () => {
    const phone = orderData?.courier?.ref?.phone;
    phone && window.open(`tel:${phone}`);
  };

  const handleBack = () => {
    setOrderData(null);
    setStage(1);
  };

  const handleCancelOrder = () => {
    setOrderInfo(orderData);
    setVisible(false);
    setCancelModal(true);
  };

  return (
    <WidthController>
      <TopSection>
        <h4 style={{ margin: 0 }}>Order Tracking Query</h4>
        <Close onClick={() => setVisible(false)}>
          <IoCloseOutline />
        </Close>
      </TopSection>

      <Row style={{ width: "100%", gap: 0 }}>
        <div
          style={{
            width: stage === 1 ? "100%" : 0,
            overflow: "hidden",
            height: stage === 1 ? "100%" : 0,
            transition: transition,
          }}
        >
          <Info>
            If you received a{" "}
            <strong style={{ color: theme.orange, fontStyle: "italic" }}>
              shared url
            </strong>
            , load it from your browser url to auto input track data.
          </Info>

          <form onSubmit={handleSubmit}>
            <LinkWrapper>
              <GlobalTextInput
                placeholder="Enter order code.."
                style={{
                  padding: 0,
                  flex: 1,
                  background: "transparent",
                  border: "none",
                  borderRadius: 0,
                }}
                name="code"
                value={values}
                onChange={(e) => setValues(e.target.value)}
              />
              <PasteButton onClick={handlePaste}>
                Paste
                <GoPaste />
              </PasteButton>
            </LinkWrapper>

            <Info>
              This is the 5 lettered code you receive when you order a request.
            </Info>

            <TextInputWrapper
              style={{
                padding: "10px 10px",
                border: "1px solid #e5e7eb",
                marginTop: 10,
              }}
            >
              <select
                style={{
                  fontSize: 18,
                  background: "transparent",
                  border: "none",
                  width: 100,
                  outline: "none",
                  color: theme.font,
                }}
                value="233"
              >
                {countryCodes.map((data, index) => (
                  <option
                    key={index}
                    value={data.code}
                  >{`${data.flag} +${data.code}`}</option>
                ))}
              </select>
              <TextInputRawWrapper
                placeholder="Enter sender's no."
                type="number"
                style={{ fontSize: 18 }}
                minLength={9}
                maxLength={9}
                required
                defaultValue={preload.pickupPhone}
                name="pickupPhone"
              />
            </TextInputWrapper>

            <Info>This is the number of the pickup person for your order.</Info>

            <FormMessage
              message={formMessage.message}
              status={formMessage.status}
            />

            {loading ? (
              <Loader
                message="querying order"
                color={theme.orange}
                style={{
                  marginTop: 10,
                  fontSize: 12,
                }}
              />
            ) : (
              <GlobalButton
                style={{
                  borderRadius: 10,
                  width: "100%",
                  backgroundColor: theme.font,
                  color: theme.background,
                  marginTop: 15,
                }}
                type="submit"
              >
                Send
              </GlobalButton>
            )}
          </form>
        </div>

        <div
          style={{
            width: stage === 2 ? "100%" : 0,
            overflow: "hidden",
            height: stage === 2 ? "100%" : 0,
            transition: transition,
          }}
        >
          {orderData && (
            <>
              <div
                style={{
                  height: 200,
                  borderRadius: 10,
                  marginTop: 20,
                  overflow: "hidden",
                  width: "100%",
                }}
              >
                <MapPreview
                  start={orderData.pickup}
                  waypoints={orderData.drop_offs}
                  orderDetails={orderData}
                />
              </div>

              <OrderDetails
                code={orderData.code}
                insights={orderData.insights}
                orderRoutes={orderData}
              />

              <Row
                style={{
                  justifyContent: "space-between",
                  marginTop: 20,
                  alignItems: "center",
                }}
              >
                {stage === 2 && (
                  <GlobalButton
                    style={{
                      borderRadius: 10,
                      width: "20%",
                      backgroundColor: theme.font,
                      color: theme.background,
                    }}
                    type="button"
                    onClick={handleBack}
                  >
                    <IoArrowBack size={15} />
                  </GlobalButton>
                )}

                {stage === 1 ? (
                  <GlobalButton
                    style={{
                      borderRadius: 10,
                      width: "100%",
                      backgroundColor: getColorByStatus(orderData.status),
                      color: theme.font,
                      marginTop: 15,
                    }}
                    type="submit"
                  >
                    {orderData.status?.replaceAll("_", " ")}
                  </GlobalButton>
                ) : orderData.status === EDeliveryStatus.PENDING ? (
                  <GlobalButton
                    style={{
                      borderRadius: 10,
                      width: "100%",
                      color: theme.red_light,
                    }}
                    background={theme.accent_gray}
                    hoverColor={theme.accent}
                    onClick={handleCancelOrder}
                    type="button"
                  >
                    Cancel Order
                  </GlobalButton>
                ) : null}

                {stage !== 1 && contactCourier.isActive && (
                    <div
                      style={{
                        height: 35,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <img
                        src={require("../../../assets/delivery-man.png")}
                        style={{ height: 40, width: 40 }}
                        alt="Delivery Person"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "center",
                        }}
                      >
                        <span
                          style={{
                            margin: 0,
                            padding: 0,
                            fontSize: 12,
                            fontWeight: 600,
                          }}
                        >
                          {orderData?.courier?.ref?.username}
                        </span>
                        <span style={{ fontSize: 12, margin: 0 }}>
                          {orderData?.courier?.ref?.phone}
                        </span>
                      </div>
                    </div>
                )}

                {stage !== 1 && contactCourier.canCall && (
                  <GlobalButton
                    style={{
                      borderRadius: 10,
                      width: "20%",
                      backgroundColor: theme.green_light,
                      color: theme.background,
                    }}
                    type="button"
                    onClick={handleCallCourier}
                  >
                    <IoCall size={15} color={theme.font} />
                  </GlobalButton>
                )}
              </Row>
            </>
          )}
        </div>
      </Row>
    </WidthController>
  );
};

export default QueryOrder;
