import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import styled from 'styled-components';

interface SwipableProps {
  images: string[];
  autoplayDelay?: number;
  triggerSwipe?: boolean;
  onSlideChange?: (index: number) => void;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const SlideContainer = styled.div<{ translateX: number }>`
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  transform: translateX(${props => props.translateX}%);
`;

const Slide = styled.div`
  min-width: 100%;
  height: 100%;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.3);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }
`;

const PrevButton = styled(NavigationButton)`
  left: 10px;
`;

const NextButton = styled(NavigationButton)`
  right: 10px;
`;

const Swipable: React.FC<SwipableProps> = ({
  images,
  autoplayDelay = 3000,
  triggerSwipe = false,
  onSlideChange
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [translateX, setTranslateX] = useState(0);

  const goToNext = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(nextIndex);
    setTranslateX(-nextIndex * 100);
    onSlideChange?.(nextIndex);
  };

  const goToPrev = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(prevIndex);
    setTranslateX(-prevIndex * 100);
    onSlideChange?.(prevIndex);
  };

  useEffect(() => {
    const interval = setInterval(goToNext, autoplayDelay);
    return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {
    if (triggerSwipe) {
      goToNext();
    }
  }, [triggerSwipe]);

  return (
    <Container>
      <SlideContainer translateX={translateX}>
        {images.map((image, index) => (
          <Slide key={index}>
            <Image src={image} alt={`Slide ${index + 1}`} />
          </Slide>
        ))}
      </SlideContainer>
      <PrevButton onClick={goToPrev}><MdKeyboardArrowLeft /></PrevButton>
      <NextButton onClick={goToNext}><MdKeyboardArrowRight /></NextButton>
    </Container>
  );
};

export default Swipable;

