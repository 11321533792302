const MAX_DELIVERIES = 15;
const env: "production" | "localhost" = "production"

export default {
  MAX_DELIVERIES,
  BASE_URL: {
    localhost: "http://localhost:8080",
    production: "https://ced-1080372686006.europe-west1.run.app",
  },
  ENV: env,
};