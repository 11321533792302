import { EDeliveryStatus } from "../../types/interfaces";

export const isDeliveryActive = (status: EDeliveryStatus): boolean => {
  const activeStatuses = [
    EDeliveryStatus.ON_THE_WAY_TO_PICKUP,
    EDeliveryStatus.PICKED_UP,
    EDeliveryStatus.IN_PROGRESS,
    EDeliveryStatus.RETURNING,
  ];

  return activeStatuses.includes(status);
};


export const canCallCourier = (status: EDeliveryStatus): boolean => {
  const callableStatuses = [
    EDeliveryStatus.IN_PROGRESS,
    EDeliveryStatus.CONFIRMED,
    EDeliveryStatus.ON_THE_WAY_TO_PICKUP,
    EDeliveryStatus.PICKED_UP,
    EDeliveryStatus.RETURNING
  ];

  return callableStatuses.includes(status);
};
