import validator from "validator";
import { generateGoogleMapsUrl } from "./map.service";
import { theme } from "../theme";
import { EDeliveryStatus } from "../types/interfaces";

export function tryParseJSON(str: any): any {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

export const checkPassword = (e: string) => {
  if (e === "") return;
  // console.log("Data", e);
  const strong = validator.isStrongPassword(e, {
    minLength: 6,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
  });

  return strong;
};

export function formatDeliveryRequestMessage(
  order: any,
  route: string
): string {
  console.log({ order });
  // Build the message header
  let message = `*_New Delivery Request_*\n\n`;

  // Add Origin details
  message += `*_Origin_*:\n`;
  message += `- *Address*: ${order.start.address}\n`;
  message += `- *Phone*: +233${order.start.phone}\n\n`;

  // Add Destination details (loop through if multiple)
  message += `*_Destinations_*:\n`;
  order.waypoints.forEach((data: any, index: number) => {
    message += `- *Drop off*: ${index + 1}\n`;
    message += `- *Address*: ${data.address}\n`;
    message += `- *Phone*: +233${data.phone}\n`;
    message += `- *Price*: GH₵ ${data.price}\n`;
    message += `- *Location*: ${generateGoogleMapsUrl({
      waypoints: [data.address],
    })}\n\n`;
  });

  message += `\nRoute: ${route}`;

  return message;
}

export const getColorByStatus = (status: EDeliveryStatus) => {
  switch (status) {
    case EDeliveryStatus.PENDING:
      return theme.accent_gray;
    case EDeliveryStatus.CONFIRMED:
      return theme.yellow;
    case EDeliveryStatus.CANCELLED:
      return theme.red_light;
    case EDeliveryStatus.ON_THE_WAY_TO_PICKUP:
      return theme.yellow;
    case EDeliveryStatus.PICKED_UP:
      return theme.yellow;
    case EDeliveryStatus.IN_PROGRESS:
      return theme.yellow;
    default:
      return theme.green_light;
  }
};

export const getSharableUrl = (code: string, phone: string) => {
  return `${window.location.origin}?popup=tracking&code=${code}&pickup=${phone}`;
};
