import styled from "styled-components";
import {
  GlobalButton,
  Link,
  Row,
  RowColumns,
  TextInputRaw,
  theme,
} from "../../theme";
import { IoSearch } from "react-icons/io5";
import { BsPlayFill } from "react-icons/bs";
import { RiMotorbikeFill } from "react-icons/ri";
import { FaBagShopping } from "react-icons/fa6";
import { PiPackageFill } from "react-icons/pi";
import Modal from "../Shared/Modal.component";
import { useEffect, useState } from "react";
import QueryOrder from "../Shared/Modals/QueryOrder.component";
import OrderRequest from "../Shared/Modals/OrderRequest.component";
import usePath from "../../hooks/usePath";
import CancelOrder from "../Shared/Modals/CancelOrder.component";

const Introduction = () => {
  const { params } = usePath();
  const [visible, setVisible] = useState<boolean>(false);
  const [preload, setPreload] = useState({ code: "", pickupPhone: "" });
  const [orderData, setOrderData] = useState<any>(null);
  const [orderVisible, setOrderVisible] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);

  useEffect(() => {
    if (params["popup"] === "tracking" && params["code"] && params["pickup"]) {
      setTimeout(() => {
        setVisible(true);
        setPreload({ code: params["code"], pickupPhone: params["pickup"] });
      }, 2000);
    }
  }, [params]);

  useEffect(() => {
    if (!cancelModal) setOrderData(null);
  }, [cancelModal, visible]);

  return (
    <>
      <Container id="services">
        <Circle />
        <RowColumns style={{ zIndex: 1 }} width="50%">
          <Catch>
            Fastest <br />{" "}
            <span style={{ color: theme.orange, fontFamily: "inherit" }}>
              Delivery
            </span>{" "}
            &
            <br /> Easy{" "}
            <span style={{ color: theme.orange, fontFamily: "inherit" }}>
              Pickup
            </span>
          </Catch>
          <Row>
            <Icon src={require("../../assets/happy_guy.png")} alt="Happy guy" />
            <span style={{ fontWeight: 500 }}>
              When you're lazy to move,
              <br /> we are just a click away!
            </span>
          </Row>
          <Row style={{ marginTop: 50, marginBottom: 20 }}>
            <SearchContainer>
              <IoSearch />
              <button
                style={{
                  border: "none",
                  borderLeft: "1px solid white",
                  marginLeft: 10,
                  paddingLeft: 10,
                  fontWeight: 500,
                  width: 120,
                  outline: "none",
                  color: "#828080",
                  textAlign: "left",
                  background: "transparent",
                  fontSize: 16,
                  cursor: "pointer",
                }}
                onClick={() => setVisible(true)}
              >
                Track order
              </button>
            </SearchContainer>
            {/* <Link to="/signin"> */}
            <GlobalButton
              style={{ border: "none", padding: 0, paddingRight: 15 }}
              onClick={() => setOrderVisible(true)}
            >
              <span
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                  marginRight: 5,
                }}
              >
                <BsPlayFill
                  color={theme.orange}
                  size={30}
                  style={{ marginLeft: 5 }}
                />
              </span>{" "}
              Order Now
            </GlobalButton>
            {/* </Link> */}
          </Row>
          <Row style={{ marginTop: 50, gap: 20 }}>
            <CategoryContainer
              animation="fade-right"
              icon={require("../../assets/pizza.png")}
              name="Food"
              description="Favourite restaurants"
            />
            <CategoryContainer
              icon={require("../../assets/documents.png")}
              name="Documents"
              description="Office files and accesories"
            />
            <CategoryContainer
              animation="fade-left"
              icon={require("../../assets/smart_watch.png")}
              name="Electronic"
              description="Gadgets and components"
            />
          </Row>
        </RowColumns>
        <RowColumns
          width="50%"
          style={{ position: "relative", marginBottom: 20 }}
        >
          <Circle
            style={{
              opacity: 0.5,
              backgroundColor: "white",
              left: -50,
              top: -50,
            }}
            // data-aos="fade-down"
          />
          <Image
            src={require("../../assets/images/riding.jpg")}
            alt="delivery guy"
            // data-aos="fade-left"
          />
          {/* <RightSection>
            
            <BenefitsWrapper>
              <Row>
                <CircularWrapper>
                  <RiMotorbikeFill />
                </CircularWrapper>
                <div>
                  <Title>Fast delivery</Title>
                  <Description>
                    Promise to deliver in some few minutes. <br />
                    Quick as possible!
                  </Description>
                </div>
              </Row>
              <Row>
                <CircularWrapper>
                  <FaBagShopping />
                </CircularWrapper>
                <div>
                  <Title>Pick up</Title>
                  <Description>
                    Pickup delivery right at your doorstep, <br />
                    simple and easy.
                  </Description>
                </div>
              </Row>
              <Row>
                <CircularWrapper>
                  <PiPackageFill />
                </CircularWrapper>
                <div>
                  <Title>Packaging</Title>
                  <Description>
                    Enjoy your food and groceries in good
                    <br />
                    state, handled with care.
                  </Description>
                </div>
              </Row>
            </BenefitsWrapper>
          </RightSection> */}
        </RowColumns>
      </Container>
      <Modal visible={visible} setVisible={setVisible} preventClose={true}>
        {
          <QueryOrder
            visible={visible}
            setVisible={setVisible}
            preload={preload}
            setCancelModal={setCancelModal}
            setOrderInfo={setOrderData}
            orderData={orderData}
            setOrderData={setOrderData}
          />
        }
      </Modal>
      <Modal
        visible={orderVisible}
        setVisible={setOrderVisible}
        preventClose={true}
      >
        {<OrderRequest setVisible={setOrderVisible} />}
      </Modal>
      <Modal
        visible={cancelModal}
        setVisible={setCancelModal}
        // preventClose={true}
      >
        <CancelOrder
          preload={{
            code: orderData?.code || "",
            phone: orderData?.pickup?.phone,
          }}
          setVisible={setCancelModal}
        />
      </Modal>
    </>
  );
};

const CategoryContainer = ({
  icon,
  name,
  description,
  animation = "fade-up",
}: {
  icon: any;
  name: string;
  description: string;
  animation?: "fade-up" | "fade-left" | "fade-right";
}) => {
  return (
    <CategoryWrapper data-aos={animation}>
      <div style={{ position: "absolute", top: -30 }}>
        <img src={icon} alt={name} height={80} width={80} />
      </div>
      <div style={{ marginTop: 55, textAlign: "center" }}>
        <h4
          style={{
            margin: 0,
            fontSize: 16,
            fontWeight: 600,
            fontFamily: '"Yrsa", serif',
          }}
        >
          {name}
        </h4>
        <p
          style={{
            margin: 0,
            fontSize: 12,
            color: theme.gray,
            fontWeight: 500,
            marginTop: 10,
          }}
        >
          {description}
        </p>
      </div>
    </CategoryWrapper>
  );
};

const Container = styled.div`
  padding: 0 6%;
  display: flex;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CategoryWrapper = styled.div`
  position: relative;
  width: 100px;
  height: 130px;
  background-color: ${theme.accent_gray};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Circle = styled.span`
  height: 150px;
  width: 150px;
  position: absolute;
  top: 10px;
  left: -10px;
  background-color: ${theme.accent};
  border-radius: 50%;
  z-index: 0;

  @media (max-width: 768px) {
    height: 80px;
    width: 80px;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  gap: 40px;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BenefitsWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 60px;

  @media (max-width: 768px) {
    align-items: flex-start;
    gap: 20px;
  }
`;

export const Catch = styled.h1`
  font-family: "Yrsa", serif;
  font-weight: 700;
  font-style: normal;
  font-size: 60px;
  line-height: 60px;
  margin: 0 0 50px 0;

  @media (max-width: 768px) {
    font-size: 30px !important;
    line-height: 30px !important;
    z-index: 1 !important;
  }
`;

const Icon = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 500px;
  object-fit: cover;
  border-radius: 30px;

  @media (max-width: 768px) {
    height: 300px;
    margin: 20px 0;
  }
`;

const SearchContainer = styled.div`
  padding: 14px;
  border-radius: 30px;
  background-color: ${theme.font};
  color: white;
  display: flex;
  align-items: center;
`;

export const CircularWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 20px;
  margin: 0;
  margin-bottom: 5px;
  font-family: "Yrsa", serif;
`;

export const Description = styled.p`
  font-size: 14px;
  color: gray;
  font-weight: 500;
  margin: 0;
`;

export default Introduction;
