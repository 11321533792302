import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  GlobalButton,
  GlobalButtonPlain,
  Link,
  Row,
  TextInputRawWrapper,
  TextInputWrapper,
  theme,
  transition,
} from "../theme";
import { Catch } from "../components/Landing/Introduction.section";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { LuSmartphone } from "react-icons/lu";
import { CiMail } from "react-icons/ci";
import { CgPassword } from "react-icons/cg";
import "react-phone-number-input/style.css";
import countryCodes from "../utils/country_codes.json";
import { PinInput } from "react-input-pin-code";
import Loader from "../components/Shared/Loader.component";
import { LiaUserLockSolid } from "react-icons/lia";
import { formHandler } from "../services/form_handler.service";
import { FiUser } from "react-icons/fi";
import useCookies from "../hooks/useCookies";
import useExternalAPI from "../hooks/useExternalAPI";
import FormMessage from "../components/Shared/FormMessage.component";
import usePath from "../hooks/usePath";
import { checkPassword } from "../services/data_handler.service";
import { Info } from "../styles/modal.styles";

const Signup = () => {
  const [type, setType] = useState<"email" | "phone" | "authentiate">("email");
  const [pin, setPin] = useState(["", "", "", "", ""]);
  const [pin_confirm, setPinConfirm] = useState(["", "", "", "", ""]);
  const [verification_pin, setVerificationPin] = useState(["", "", "", "", ""]);
  const [verification_account, setVerificationAccount] = useState<{
    value: null | string;
    type: "email" | "phone";
  }>({ value: null, type: "email" });
  const [country_code, setCountryCode] = useState<string>("233");
  const [loading, setLoading] = useState<boolean>(false);
  const [formMessage, setFormMessage] = useState<{
    message: string | null;
    status: "success" | "info" | "error";
  }>({ message: null, status: "info" });
  const { setCookie, getCookie, deleteCookie } = useCookies();
  const { fetchData } = useExternalAPI();
  const { params } = usePath();

  useEffect(()=>{
    console.log({params})
    if(params["type"] && params["value"]){
      setType("authentiate")
      setVerificationAccount({value: params['value'], type: params["type"]})
      if (params['code'])
        setVerificationPin(params["code"].split(''))
    }
  }, [params])

  const handleSetSessionCookie = (data: object) => {
    const expirationDate = new Date();
    expirationDate.setHours(expirationDate.getHours() + 1); // 1 hour from now

    // setCookie('session', data, { expires: expirationDate });
  };

  const request = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setFormMessage((e) => ({ ...e, message: null }));

    try {
      let formData = formHandler(e);

      if (type === "email") {
        if (formData["password"] !== formData["confirm_password"])
          setFormMessage({
            status: "error",
            message: "password mismatch!",
          });

        const strongPassword = checkPassword(formData["password"]);
        if (!strongPassword) {
          setFormMessage({
            status: "error",
            message:
              "Password needs 6+ characters, including uppercase, lowercase, number, and symbol.",
          });
          return;
        }

        formData = {
          username: formData["username"],
          email: formData["email"],
          password: formData["password"],
          confirm_password: formData["confirm_password"],
        };
      } else if (type === "phone") {
        // console.log({ pin, pin_confirm });
        if (pin.includes("")) {
          setFormMessage({
            status: "error",
            message: "please fill all fields of the PIN.",
          });
          return;
        }
        if (pin_confirm.includes("")) {
          setFormMessage({
            status: "error",
            message: "please fill all fields of the confirm PIN.",
          });
          return;
        }
        if (pin.join("") !== pin_confirm.join("")) {
          setFormMessage({
            status: "error",
            message: "PIN mismatch!",
          });
          return;
        }

        formData = {
          username: formData["username"],
          phone: `+${country_code}${
            formData["phone"].startsWith("0")
              ? formData["phone"].substring(1)
              : formData["phone"]
          }`,
          pin: pin.join(""),
          confirm_pin: pin_confirm.join(""),
        };
      }

      console.log({ formData });
      // deleteCookie('session')
      // handleSetSessionCookie(formData)
      const submit = await fetchData({
        url: "account/signup",
        method: "POST",
        body: formData,
      });

      if (type === "email" || type === "phone")
        setVerificationAccount({
          value: formData["email"] || formData["phone"],
          type,
        });
      setFormMessage({ status: "success", message: `${submit["message"]}` });
      setType("authentiate");
      // console.log({signup})
    } catch (e: any) {
      console.error({ e });
      setFormMessage({
        status: "error",
        message: e?.message || "An error has occured, try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  const verify = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setFormMessage((e) => ({ ...e, message: null }));

    try {
      if (verification_pin.includes("")) {
        setFormMessage({
          status: "error",
          message: "please fill all fields of the confirm PIN.",
        });
        return;
      }

      let formData: any = {};

      formData[verification_account.type] = verification_account.value;
      formData["code"] = verification_pin.join("");

      const submit = await fetchData({
        url: "account/verifyAccount",
        method: "POST",
        body: formData,
      });

      setFormMessage({ status: "success", message: `${submit["message"]}` });
    } catch (e: any) {
      console.error({ e });
      setFormMessage({
        status: "error",
        message: e?.message || "An error has occured, try again later.",
      });
    } finally {
      setVerificationPin(["", "", "", "", ""]);
      setLoading(false);
    }
  };

  const resentOpt = async () => {
    setLoading(true);
    setFormMessage((e) => ({ ...e, message: null }));

    try {
      let formData: any = {};
      formData[verification_account.type] = verification_account.value;

      const submit = await fetchData({
        url: "account/resendOTP",
        method: "POST",
        body: formData,
      });

      setFormMessage({ status: "success", message: `${submit["message"]}` });
    } catch (e: any) {
      console.error({ e });
      setFormMessage({
        status: "error",
        message: e?.message || "An error has occured, try again later.",
      });
    } finally {
      setVerificationPin(["", "", "", "", ""]);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Left>
        <Link to="/">
          <img
            src={require("../assets/logo_min_dark.png")}
            width={50}
            height={50}
            style={{ position: "absolute", left: 20, top: 20 }}
            alt="Logo"
          />
        </Link>
        <img
          src={require("../assets/hand_shake.png")}
          style={{ width: "105%", marginTop: "20%" }}
          alt="map"
        />
        <Power>
          Powered by{" "}
          <a
            href="https://cwintechsolutions.com/"
            target="_blank"
            style={{ color: theme.orange, textDecoration: "none" }}
          >
            Cwin Tech Solutions
          </a>{" "}
          @ 2024.
        </Power>
      </Left>
      <Right
        onSubmit={(e) => (type === "authentiate" ? verify(e) : request(e))}
      >
        <Link to="/">
          <Logo src={require("../assets/logo_min_dark.png")} />
        </Link>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Catch style={{ margin: 0 }}>Sign up</Catch>
          <SignupWrapper style={{ textAlign: "left" }}>
            Get started using the options
          </SignupWrapper>
          <Row style={{ gap: 10, marginTop: 20 }}>
            <LoginType
              active={type === "email"}
              type="button"
              onClick={() => setType("email")}
            >
              <MdOutlineAlternateEmail
                size={20}
                color={type === "email" ? theme.orange : theme.font}
              />{" "}
              Email
            </LoginType>
            <LoginType
              active={type === "phone"}
              type="button"
              onClick={() => setType("phone")}
            >
              <LuSmartphone
                size={15}
                color={type === "phone" ? theme.orange : theme.font}
              />{" "}
              Phone
            </LoginType>
            {verification_account.value && (
              <LoginType
                active={type === "authentiate"}
                type="button"
                onClick={() => setType("authentiate")}
              >
                <LiaUserLockSolid
                  size={15}
                  color={type === "authentiate" ? theme.orange : theme.font}
                />{" "}
                Verify
              </LoginType>
            )}
          </Row>
          {type !== "authentiate" && (
            <InputWrapper
              type={"email"}
              style={{
                marginTop: 35,
                height: "auto",
                marginBottom: 10,
                transition: transition,
              }}
            >
              <TextInputWrapper
                style={{ padding: "10px 10px", border: "none" }}
              >
                <FiUser />
                <TextInputRawWrapper
                  placeholder="Enter username"
                  style={{ fontSize: 18 }}
                  name="username"
                  minLength={5}
                  required
                />
              </TextInputWrapper>
            </InputWrapper>
          )}
          <InputWrapper type={type}>
            <div
              style={{
                width: type === "email" ? "100%" : 0,
                opacity: type === "email" ? 1 : 0,
                transition: transition,
                overflow: "hidden",
              }}
            >
              <TextInputWrapper
                style={{ padding: "10px 10px", border: "none" }}
              >
                <CiMail />
                <TextInputRawWrapper
                  placeholder="Enter email"
                  type="email"
                  name="email"
                  style={{ fontSize: 18 }}
                  required={type === "email"}
                />
              </TextInputWrapper>
              <TextInputWrapper
                style={{ padding: "10px 10px", border: "none", marginTop: 20 }}
              >
                <CgPassword />
                <TextInputRawWrapper
                  placeholder="Enter password"
                  type="password"
                  name="password"
                  style={{ fontSize: 18 }}
                  required={type === "email"}
                />
              </TextInputWrapper>
              <TextInputWrapper
                style={{ padding: "10px 10px", border: "none", marginTop: 20 }}
              >
                <CgPassword />
                <TextInputRawWrapper
                  placeholder="Confirm password"
                  type="password"
                  name="confirm_password"
                  style={{ fontSize: 18 }}
                  required={type === "email"}
                />
              </TextInputWrapper>
            </div>
            <div
              style={{
                width: type === "phone" ? "100%" : 0,
                opacity: type === "phone" ? 1 : 0,
                transition: transition,
                overflow: "hidden",
              }}
            >
              <TextInputWrapper
                style={{
                  marginBottom: 20,
                  marginTop: 20,
                  padding: "10px 10px",
                  border: "none",
                }}
              >
                <select
                  style={{
                    fontSize: 18,
                    background: "transparent",
                    border: "none",
                    width: 100,
                    outline: "none",
                    font: theme.font,
                  }}
                  defaultValue={"233"}
                  value={country_code}
                  onChange={(e) => setCountryCode(e.target.value)}
                >
                  {countryCodes.map((data, index) => (
                    <option
                      key={index}
                      value={data.code}
                    >{`${data.flag} +${data.code}`}</option>
                  ))}
                </select>
                <TextInputRawWrapper
                  placeholder="Enter phone no."
                  type="number"
                  style={{ fontSize: 18 }}
                  required={type === "phone"}
                  name="phone"
                />
              </TextInputWrapper>
              <PinInput
                containerStyle={{
                  marginTop: 20,
                  marginBottom: 15,
                  marginLeft: 5,
                }}
                values={pin}
                size="lg"
                type="text"
                mask={true}
                validBorderColor={theme.yellow}
                focusBorderColor={theme.orange}
                onChange={(value, index, values) => setPin(values)}
              />
              <Info>
                This will be the 5 pin code you'll use in replace of password.
              </Info>
              <PinInput
                containerStyle={{
                  marginTop: 20,
                  marginBottom: 15,
                  marginLeft: 5,
                }}
                values={pin_confirm}
                size="lg"
                type="text"
                mask={true}
                validBorderColor={theme.yellow}
                focusBorderColor={theme.orange}
                onChange={(value, index, values) => setPinConfirm(values)}
              />
              <Info>Confirm the pin you typed above.</Info>
            </div>
            {verification_account.value && (
              <div
                style={{
                  width: type === "authentiate" ? "100%" : 0,
                  opacity: type === "authentiate" ? 1 : 0,
                  transition: transition,
                  overflow: "hidden",
                }}
              >
                <Info>
                  This will be the 5 pin code sent to{" "}
                  <strong>{verification_account.value}</strong>.
                </Info>
                <PinInput
                  containerStyle={{
                    marginTop: 20,
                    marginBottom: 15,
                    marginLeft: 5,
                  }}
                  values={verification_pin}
                  size="lg"
                  type="text"
                  mask={true}
                  validBorderColor={theme.yellow}
                  focusBorderColor={theme.orange}
                  onChange={(value, index, values) =>
                    setVerificationPin(values)
                  }
                />
                <Info style={{ fontStyle: "normal" }}>
                  Didn't get any OTP?{" "}
                  <GlobalButtonPlain
                    style={{ fontWeight: 600, color: theme.orange }}
                    type="button"
                    onClick={()=> resentOpt()}
                  >
                    resend
                  </GlobalButtonPlain>
                </Info>
              </div>
            )}
          </InputWrapper>
          <InputWrapper style={{ height: "auto" }} type={"email"}>
            <FormMessage
              message={formMessage.message}
              status={formMessage.status}
            />
          </InputWrapper>
          {!loading && (
            <LocalButton
              style={{
                backgroundColor: theme.font,
                color: theme.background,
                marginTop: 30,
              }}
              type="submit"
            >
              {type === "authentiate" ? "Authenticate" : "Submit"}
            </LocalButton>
          )}

          {loading && (
            <Loader
              message="signing in"
              color={theme.orange}
              style={{
                width: 300,
                marginTop: 10,
                justifyContent: "flex-start",
              }}
            />
          )}

          <SignupWrapper style={{ textAlign: "left", marginTop: 20 }}>
            Don't have an account?{" "}
            <Signin to="/signin" style={{ fontWeight: 600 }}>
              Sign in
            </Signin>
          </SignupWrapper>
        </div>
      </Right>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: 100vh;
  padding: 1%;
`;

const Left = styled.div`
  width: 40%;
  border-radius: 30px;
  background-color: #fff5f3;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Right = styled.form`
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 2%;
  justify-content: space-between;
  padding-left: 10%;

  @media (max-width: 768px) {
    padding-left: 4%;
  }
`;

const Power = styled.p`
  color: ${theme.font};
  margin: 0;
  font-size: 10px;
  font-weight: 600;
  position: absolute;
  bottom: 20px;
  left: 20px;
`;

const SignupWrapper = styled.p`
  color: #93989f;
  margin: 0;
  font-size: 16px;
  /* font-weight: 600; */
  text-align: right;
`;

const Logo = styled.img`
  display: none;
  position: absolute;
  left: 2%;
  top: 1%;
  width: 50px;
  height: 50px;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Signin = styled(Link)`
  transition: ${transition};
  color: ${theme.font};

  &:hover {
    color: ${theme.orange};
  }
`;

interface LoginTypeProps {
  active: boolean;
}

const LoginType = styled(GlobalButton)<LoginTypeProps>`
  border: 1px solid ${(props) => (props.active ? theme.orange : "#c6c6c6")};
  border-radius: 10px;
  padding: 10px 24px;
  height: 40px;
  background-color: ${(props) => (props.active ? theme.accent : "transparent")};
  gap: 7px;

  &:hover {
    border-color: ${theme.yellow};
  }
`;

const InputWrapper = styled.div<{ type: "email" | "phone" | "authentiate" }>`
  display: flex;
  flex-wrap: nowrap;
  width: 500px;
  transition: ${transition};
  align-items: center;
  height: ${(props) =>
    props.type === "email"
      ? "180px"
      : props.type === "phone"
      ? "250px"
      : "150px"};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const LocalButton = styled(GlobalButton)`
  width: 500px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default Signup;
