import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Landing from "./pages/Landing.page";
import Signin from "./pages/Signin.page";
import Signup from "./pages/Signup.page";
import { AuthProvider } from "./context/AuthProvider";
import Home from "./pages/Home.page";
import ResetPassword from "./pages/ResetPassword.page";
import NotFound from "./components/Shared/NotFound.component";
import Dashboard from "./pages/Workspace/Dashboard.page";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/signin",
    element: <Signin />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/resetpassword",
    element: <ResetPassword />,
  },
  {
    path: "/home*",
    element: <Home />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
