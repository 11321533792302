import {
  getColorByStatus,
} from "../../services/data_handler.service";
import {
  CiCircleCheck,
  CiCircleMinus,
  CiCircleMore,
  CiCircleRemove,
} from "react-icons/ci";
import {
  FaRegCircle,
  FaRegCircleCheck,
  FaRegCircleDot,
  FaRegCircleXmark,
} from "react-icons/fa6";
import { EDeliveryStatus } from "../../types/interfaces";

const OrderStatusPin = ({ status }: { status: EDeliveryStatus }) => {
  const size = 13;
  const color = getColorByStatus(status);

  const renderIcon = () => {
    switch (status) {
      case EDeliveryStatus.PENDING:
        return (
          <FaRegCircle style={{ height: size, width: size }} color={"gray"} />
        );
      case EDeliveryStatus.CANCELLED:
        return (
          <FaRegCircleXmark
            style={{ height: size, width: size }}
            color={color}
          />
        );
      case EDeliveryStatus.CONFIRMED:
        return (
          <FaRegCircleCheck
            style={{ height: size, width: size }}
            color={color}
          />
        );
      case EDeliveryStatus.IN_PROGRESS:
        return (
          <FaRegCircleDot style={{ height: size, width: size }} color={color} />
        ); // Assuming you meant to use the same icon here
      case EDeliveryStatus.DELIVERED:
        return (
          <FaRegCircleCheck
            style={{ height: size, width: size }}
            color={color}
          />
        );
      default:
        return null;
    }
  };

  return <span style={{ width: 15 }}>{renderIcon()}</span>;
};

export default OrderStatusPin;
