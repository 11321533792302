import React, { useEffect, useState } from "react";
import { Row, colors, theme } from "../../theme";
import { Label, Value } from "../Workspace/Dashboard/CurrentOrder.component";
import { GoPaste } from "react-icons/go";
import {
  getColorByStatus,
  getSharableUrl,
} from "../../services/data_handler.service";
import OrderStatusPin from "../Shared/OrderStatusPin.component";
import { PasteButton } from "../../styles/modal.styles";
import { EDeliveryStatus } from "../../types/interfaces";

const OrderDetails = ({
  code,
  insights,
  orderRoutes,
}: {
  code: string;
  insights: any;
  orderRoutes: any;
}) => {
  const [preview, setPreview] = useState<any>("");

  const loadView = () => {
    try {
      // //console.log({details, completeOrders})
      return (
        <>
          {code && (
            <Row
              style={{
                justifyContent: "space-between",
                // alignItems: "flex-end",
                marginTop: 20,
              }}
            >
              <Label
                style={{ fontSize: 18, fontWeight: 600, color: theme.font }}
              >
                Order {code}
              </Label>
              <Value style={{ display: "flex", gap: 10, alignItems: "center" }}>
                {/* // <PasteButton
                  //   onClick={() => {
                  //     navigator.clipboard.writeText(code);
                  //     alert("Link has been copied");
                  //   }}
                  //   type="button"
                  // >
                  //   Copy ID
                  //   <GoPaste />
                  // </PasteButton> */}
                <span
                  style={{
                    fontSize: 10,
                    fontWeight: 600,
                    color: orderRoutes?.status === EDeliveryStatus.PENDING ? theme.yellow : getColorByStatus(orderRoutes?.status),
                    textTransform: "uppercase",
                  }}
                >
                  {orderRoutes?.status?.replaceAll("_", " ")}
                </span>

                <PasteButton
                  onClick={() => {
                    navigator.clipboard.writeText(
                      getSharableUrl(code, orderRoutes.pickup?.phone)
                    );
                    alert("Link has been copied");
                  }}
                  type="button"
                >
                  Copy sharable url
                  <GoPaste />
                </PasteButton>
              </Value>
            </Row>
          )}
          {/* <Row
            style={{
              justifyContent: "space-between",
              alignItems: "flex-end",
              marginTop: 20,
            }}
          >
            <Label>Current status:</Label>
            <Value style={{ color: getColorByStatus(orderRoutes?.status), textTransform: "lowercase", fontSize: 12 }}>
              {orderRoutes?.status?.replace("_", " ")}
            </Value>
          </Row> */}
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "flex-end",
              marginTop: 20,
            }}
          >
            <Label>Total distance:</Label>
            <Value>{insights?.totalDistance} Km</Value>
          </Row>
          <Row
            style={{ justifyContent: "space-between", alignItems: "flex-end" }}
          >
            <Label>Estimated duration:</Label>
            <Value>{insights?.totalDuration} minutes</Value>
          </Row>
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "flex-end",
              // marginTop: 10,
            }}
          >
            <Label>Pickup location:</Label>
            <Value>{orderRoutes.pickup?.address}</Value>
          </Row>
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "flex-end",
              marginTop: 10,
            }}
          >
            <Label>Cost breakdown</Label>
          </Row>
          {orderRoutes?.drop_offs?.length > 0 && (
            <div
              style={{
                borderTop: "1px solid " + theme.accent_gray,
                borderBottom: "1px solid " + theme.accent_gray,
                padding: "0 5px",
                marginBottom: 7,
              }}
            >
              {orderRoutes?.drop_offs?.map((data: any, index: number) => (
                <Row
                  style={{
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    paddingTop: 3,
                    marginBottom: 3,
                  }}
                >
                  <Label
                    style={{
                      fontSize: 12,
                      display: "flex",
                      alignItems: "flex-start",
                      gap: 3,
                    }}
                  >
                    {orderRoutes?.status !== EDeliveryStatus.PENDING && (
                      <OrderStatusPin status={data?.status} />
                    )}{" "}
                    {data?.address || ""}:
                  </Label>
                  <Value
                    style={{
                      fontSize: 12,
                      color: "#858599",
                      minWidth: 70,
                      textAlign: "right",
                      alignSelf: "flex-start",
                    }}
                  >
                    GH₵ {data?.price}
                  </Value>
                </Row>
              ))}
            </div>
          )}
          <Row
            style={{ justifyContent: "space-between", alignItems: "flex-end" }}
          >
            <Label>Total estimated price:</Label>
            <Value>
              GH₵{" "}
              {parseFloat(
                orderRoutes?.drop_offs?.reduce(
                  (acc: any, item: any) => acc + item.price,
                  0
                )
              ).toFixed(2)}
            </Value>
          </Row>
        </>
      );
    } catch (e) {
      //console.error({ preview_error: e });
      return "";
    }
  };

  useEffect(() => {
    //console.log({ code, insights, orderRoutes });
    setPreview(loadView());
  }, [code, insights, orderRoutes]);

  return preview;
};

export default OrderDetails;
