export enum EDeliveryStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  ON_THE_WAY_TO_PICKUP = "ON_THE_WAY_TO_PICKUP",
  PICKED_UP = "PICKED_UP",
  CANCELLED = "CANCELLED",
  IN_PROGRESS = "IN_PROGRESS",
  RETURNING = "RETURNING",
  DELIVERED = "DELIVERED",
  COMPLETED = "COMPLETED",
}

export type IDeliverStatus =
  | "Pending"
  | "Accepted"
  | "Cancelled"
  | "In progress"
  | "Completed";

export interface IDeliveryOrder {
  id: string | number;
  image_urls: Array<any>;
  address: {
    start: string;
    end: string;
  };
  status: IDeliverStatus;
  estimated_price: number;
  items: number
}

export interface ILocation {
  lat: number;
  lng: number;
}

export interface ILocationAddress extends ILocation {
  address: string;
  status: string;
  phone?: string
}

export enum EDeliveryPickupStatus {
  PENDING = "PENDING",
  ON_THE_WAY_TO_PICKUP = "ON_THE_WAY_TO_PICKUP",
  PICKED_UP = "PICKED_UP",
}

export interface Delivery {
  address: string;
  phone: string;
  location?: ILocation;
}

export const isDeliveryActive = (status: EDeliveryStatus): boolean => {
  const activeStatuses = [
    EDeliveryStatus.ON_THE_WAY_TO_PICKUP,
    EDeliveryStatus.PICKED_UP,
    EDeliveryStatus.IN_PROGRESS,
    EDeliveryStatus.RETURNING
  ];
  
  return activeStatuses.includes(status);
};