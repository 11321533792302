import { useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import config from "../config";
import { ILocation } from "../types/interfaces";

type CustomUpdate = {
  [key: string]: any;
};

export const useSocket = (
  url: string = config.BASE_URL[config.ENV],
  courier_location?: ILocation
) => {
  const socketRef = useRef<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [driverLocation, setDriverLocation] = useState<ILocation>();
  const [customUpdates, setCustomUpdates] = useState<CustomUpdate[]>([]);
  const [refreshRequest, setRefreshRequest] = useState<Date | null>(null);

  useEffect(() => {
    // Initialize the socket connection
    socketRef.current = io(url, {
      transports: ["websocket", "polling"],
    });

    // Handle socket connection
    socketRef.current.on("connect", () => {
      setIsConnected(true);
      //console.log("Connected to socket server");
    });

    // Handle socket disconnection
    socketRef.current.on("disconnect", () => {
      setIsConnected(false);
      //console.log("Disconnected from socket server");
    });

    // Clean up the socket connection on unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [url]);

  const createOrJoinRoom = (roomName: string) => {
    if (socketRef.current) {
      socketRef.current.emit("createOrJoinRoom", roomName);
    }
  };

  const leaveRoom = (roomName: string) => {
    if (socketRef.current) {
      socketRef.current.emit("leaveRoom", roomName);
    }
  };

  const updateDriverLocation = (roomName: string, location: ILocation) => {
    if (socketRef.current) {
      socketRef.current.emit("updateDriverLocation", { roomName, location });
    }
  };

  const sendCustomUpdate = (update: CustomUpdate) => {
    if (socketRef.current) {
      socketRef.current.emit("customUpdate", update);
    }
  };

  useEffect(() => {
    const socket = socketRef.current;

    if (socket) {
      socket.on("driverLocationUpdated", (location: ILocation) => {
        //console.log({location})
        setDriverLocation(location);
      });

      socket.on("customUpdateReceived", (update: CustomUpdate) => {
        setCustomUpdates((prev) => [...prev, update]);
      });

      socket.on("refreshRequest", (data: { timestamp: Date }) => {
        //console.log("Refresh request received:", data);
        setRefreshRequest(data.timestamp);
      });
    }

    return () => {
      if (socket) {
        socket.off("driverLocationUpdated");
        socket.off("customUpdateReceived");
        socket.off("refreshRequest");
      }
    };
  }, []);

  return {
    isConnected,
    createOrJoinRoom,
    leaveRoom,
    updateDriverLocation,
    sendCustomUpdate,
    driverLocation,
    customUpdates,
    refreshRequest,
  };
};
